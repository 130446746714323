var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"createProduct"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('Añadir Producto'))+" ")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"product-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('codigoArticulo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('codigoArticulo'),"label-for":"product-codigoArticulo"}},[_c('b-form-input',{attrs:{"name":"codart","placeholder":_vm.$t('codigoArticulo')},model:{value:(_vm.codart),callback:function ($$v) {_vm.codart=$$v},expression:"codart"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ean13')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('ean13'),"label-for":"product-ean13"}},[_c('b-form-input',{attrs:{"name":"ean13","placeholder":_vm.$t('ean13')},model:{value:(_vm.ean13),callback:function ($$v) {_vm.ean13=$$v},expression:"ean13"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Modelo')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Modelo'),"label-for":"product-modelo"}},[_c('b-form-input',{attrs:{"name":"modelo","placeholder":_vm.$t('Modelo')},model:{value:(_vm.modelo),callback:function ($$v) {_vm.modelo=$$v},expression:"modelo"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Fabricante')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Fabricante'),"label-for":"product-fabricante"}},[_c('b-form-input',{attrs:{"name":"fabricante","placeholder":_vm.$t('Fabricante')},model:{value:(_vm.fabricante),callback:function ($$v) {_vm.fabricante=$$v},expression:"fabricante"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Categoría'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Categoría'),"label-for":"product-category"}},[_c('SelectCategories',{model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('TieneCaducidad')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('TieneCaducidad'),"label-for":"product-caducidad"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"caducidad","name":"check-button-caducidad","switch":"","inline":""},model:{value:(_vm.caducidad),callback:function ($$v) {_vm.caducidad=$$v},expression:"caducidad"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.caducidad)?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('CaducidadAños'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CaducidadAños'),"label-for":"product-caducidadAnos"}},[_c('b-form-input',{attrs:{"name":"caducidadAnos","type":"number","placeholder":_vm.$t('CaducidadAños')},model:{value:(_vm.caducidadAnos),callback:function ($$v) {_vm.caducidadAnos=$$v},expression:"caducidadAnos"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2950597262)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Revisión obligatoria')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Revisión obligatoria'),"label-for":"product-revision-obligatoria"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"revision-obligatoria","name":"check-button-revision-obligatoria","switch":"","inline":""},model:{value:(_vm.revisionObligatoria),callback:function ($$v) {_vm.revisionObligatoria=$$v},expression:"revisionObligatoria"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Revisión periódica')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Revisión periódica'),"label-for":"product-revision-periodica"}},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":"revision-periodica","disabled":_vm.revisionPeriodicaDisabled,"name":"check-button-revision-periodica","switch":"","inline":""},model:{value:(_vm.revisionPeriodica),callback:function ($$v) {_vm.revisionPeriodica=$$v},expression:"revisionPeriodica"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.revisionPeriodica)?_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Frecuencia revisiones'),"rules":_vm.revisionPeriodicaRequired},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Frecuencia revisiones'),"label-for":"product-subgrupo"}},[_c('v-select',{attrs:{"filterable":true,"searchable":true,"options":_vm.selectRevisiones,"placeholder":_vm.$t('Frecuencia revisiones')},model:{value:(_vm.frecuencia),callback:function ($$v) {_vm.frecuencia=$$v},expression:"frecuencia"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2322527441)})],1):_vm._e(),_c('b-col',{attrs:{"sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Descripcion')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"product-description"}},[_c('quill-editor',{model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Imagen'),"label-for":"product-image"}},[_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.imagen,"max-files":"1"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('certificadoConformidad'),"label-for":"product-certificadoConformidad"}},[_c('ImageDropzone',{ref:"certificadoConformidad",attrs:{"files":_vm.certificadoConformidad,"max-files":"1"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('fichaTecnica'),"label-for":"product-fichaTecnica"}},[_c('ImageDropzone',{ref:"fichaTecnica",attrs:{"files":_vm.fichaTecnica,"max-files":"1"}})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('manualUso'),"label-for":"product-manualUso"}},[_c('ImageDropzone',{ref:"manualUso",attrs:{"files":_vm.manualUso,"max-files":"1"}})],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Enviar'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }